import React from "react";
import styled from "styled-components";

const KadeUserManual = () => {
  return (
    <Container>
      <h2>User Manual:</h2>
    </Container>
  );
};

export default KadeUserManual;

const Container = styled.div`
  color: black;
  height: calc(100vh - 100px);
`;
