import React from "react";
import styled from "styled-components";

const EqWebConfigDownloads = () => {
  return (
    <Container>
      <h2>Download Links:</h2>
    </Container>
  );
};

export default EqWebConfigDownloads;

const Container = styled.div`
  color: black;
  height: 100vh;
`;
